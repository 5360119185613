import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src170988513/src/solar-energy-partners/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Hero = makeShortcode("Hero");
const HeroImage = makeShortcode("HeroImage");
const HeroContent = makeShortcode("HeroContent");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Button = makeShortcode("Button");
const Section = makeShortcode("Section");
const ContactStrip = makeShortcode("ContactStrip");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Hero mdxType="Hero">
      <HeroImage mdxType="HeroImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1920px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACBf/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAASPFkNdzpH//xAAZEAEBAAMBAAAAAAAAAAAAAAABAgADESP/2gAIAQEAAQUCHmQw662It+eVAP8A/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBJB/9oACAEDAQE/AVg//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EABwQAAIABwAAAAAAAAAAAAAAAAARARASQUJRgf/aAAgBAQAGPwKw3CrRiJHZf//EABkQAQADAQEAAAAAAAAAAAAAAAEAEUEhUf/aAAgBAQABPyG2YrsxAYlaMeREIIJA4LsMQWp//9oADAMBAAIAAwAAABB7D//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQMBAT8QUrxZf//EABgRAAMBAQAAAAAAAAAAAAAAAAABEVFh/9oACAECAQE/EJSul6f/xAAdEAEAAwEAAgMAAAAAAAAAAAABABEhMUFhcYGh/9oACAEBAAE/EFCPRYchzUSJbtQcBG1Oj9giqgT0vfqB3KbPl8Slpc2f/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b7ee4335bedc91471874cb8880bd8f91/d3be9/careers.webp 480w", "/static/b7ee4335bedc91471874cb8880bd8f91/e46b2/careers.webp 960w", "/static/b7ee4335bedc91471874cb8880bd8f91/882b9/careers.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b7ee4335bedc91471874cb8880bd8f91/7cc5e/careers.jpg 480w", "/static/b7ee4335bedc91471874cb8880bd8f91/6a068/careers.jpg 960w", "/static/b7ee4335bedc91471874cb8880bd8f91/0f98f/careers.jpg 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/b7ee4335bedc91471874cb8880bd8f91/0f98f/careers.jpg",
                "alt": "Solar Energy Lifestlye Careers",
                "title": "Solar Energy Lifestlye Careers",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </HeroImage><HeroContent mdxType="HeroContent">
        <Container mdxType="Container">
          <Row mdxType="Row"><Column sm={60} mdxType="Column">
              <h1>{`Long Term Careers At SEP`}</h1>
              <p style={{
                fontSize: '1.2rem'
              }}>Opportunities like this don't come around everyday.</p>
              <Button as='a' href='#inquire' mdxType="Button">Start Your Career</Button>
            </Column></Row>
        </Container>
      </HeroContent></Hero>
    <Section id='inquire' mdxType="Section"><Container style={{
        position: 'relative',
        zIndex: 1
      }} mdxType="Container">
        <Row reverse mdxType="Row">
          <Column sm={40} mdxType="Column">
            <ContactStrip textAfter='<p style="font-size: 14px; margin-top: 50px">Employees may be required to submit to a criminal history check, and provide local and/or state licensing according to state requirements. This is a 1099, commission-based position. Because sales reps are paid commissions based on their individual performance, individual earnings will vary.</p>' mdxType="ContactStrip">
              <h3 style={{
                color: 'white',
                textAlign: 'center'
              }}>Apply Here</h3>
              <p style={{
                textAlign: 'center'
              }}>Take the leap and jumpstart your career with Solar Energy Partners. Join our team of hard-working salesmen and gain control of your future.</p>
            </ContactStrip>
          </Column>
          <Column sm={60} mdxType="Column">
            <p style={{
              marginBottom: 50
            }}>
Solar Energy Partners is one of the leading providers of residential alternative energy services. <strong>Our mission is to help our customers save money by converting to solar and therefore help create a more environmental friendly atmosphere for everyone!</strong>
            </p>
            <h2>{`Join the Team!`}</h2>
            <p style={{
              marginBottom: 50
            }}>
We are looking for highly talented and self motivated individuals to join our local sales team. As one of our team members you will help customers in your assigned area design and deploy a strategy to save money on the highest utility costs in the home.
            </p>
            <h3>{`Position Details:`}</h3>
            <p style={{
              marginBottom: 50
            }}>
Currently we are seeking Full-Time highly motivated sales representatives to present the benefits of our services & the broader benefits of alternative energy to potential customers. Our well trained sales representatives educate potential customers on the benefits of our services. Our representatives are taught how to generate leads, work through referrals and how to effectively direct canvass. We generate leads through multiple methods including but not limited to: Referrals, Social Media, Fairs & Home show booths, and Direct to Home.
            </p>
            <h3>{`responsibilities:`}</h3>
            <p style={{
              marginBottom: 50
            }}>
Educating potential customers on the benefits of solar energy and how they can take advantage of other new and emerging technologies. Our sales representatives enjoy the ability to offer multiple different products to potential customers. This allows our reps to make higher income while still enjoying the freedom and flexibility of their position.
            </p>
            <h3>{`requirements:`}</h3>
            <ul>
              <li parentName="ul">{`Must have strong work ethic.`}</li>
              <li parentName="ul">{`Enjoy talking to people face to face.`}</li>
              <li parentName="ul">{`Excellent communication skills.`}</li>
              <li parentName="ul">{`Positive attitude & problem solving skills.`}</li>
              <li parentName="ul">{`Coach-ability and a desire to learn from some of the most talented leaders in the industry.`}</li>
            </ul>
            <strong>We are looking for highly motivated, friendly, competitive people to join our team!</strong>
            <p>{`Because of our massive growth, we are in all out talent acquisition mode -- looking for the best and brightest. Therefore regardless of your situation we are willing to meet with candidates at any range of interest to allow us to find the best and allow candidates to see that Golden Valley offers them the opportunity they have been looking for.`}</p>
            <Button as='a' href='#inquire' mdxType="Button">Get Started</Button>
          </Column></Row>
      </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      